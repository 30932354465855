import React, {Fragment} from 'react';
import Navigation from "./Navigation";
import CardBalanceContent from './CardbalanceContent';
import Footer from './Footer';
const MyVisaCard = () => {
    return (
        <Fragment>
            <Navigation />
            <CardBalanceContent />
            <Footer />
        </Fragment>
    );
}
export default MyVisaCard;