import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Form, InputNumber, message, Input, Button } from 'antd';
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import './DahonWarrantyRebate.css';
import { gql, useLazyQuery } from '@apollo/client';
import { patterns,links } from '../Constants';
import { useSearchParams } from 'react-router-dom';
import CodeBanner from './CodeBanner';

const TrackPayment = () => {

    const [form] = Form.useForm();
    const [status, setStatus] = useState();
    const [getParam, setParam] = useSearchParams()


    const GET_STATUS = gql`
    query getStatusByTrackingNumber($trackingNumber:Float!){
        getStatusByTrackingNumber(trackingNumber: $trackingNumber) {
          trackedStatus
        }
      }`

    useEffect(() => {
        if (getParam.get('trackingNumber')) {
            form.setFieldsValue({
                trackingNumber: getParam.get('trackingNumber')
            })
            getStatus({ variables: { trackingNumber: +getParam.get('trackingNumber') } })
        }
    }, [])

  
    const [getStatus] = useLazyQuery(GET_STATUS, {
      onCompleted(status) {
        let arrofstatus="";
        arrofstatus=status.getStatusByTrackingNumber.trackedStatus.split('|');
        if(status.getStatusByTrackingNumber.trackedStatus.includes("DECLINED_STATUS")){
          let message = (
            <>
            <p>
              Your Warranty Rebate has been declined. Please Contact{" "}
              <a href={links.dahoncontactus}> Customer Service </a>if you have
              any questions.
            </p>
            <p>{arrofstatus[1]}</p>
            <p>{arrofstatus[2]}</p>
            </>
          );
          setStatus(message);
        }
        else if(status.getStatusByTrackingNumber.trackedStatus.includes("REVIEW_STATUS")){
          let message = (
            <p>
              Your Warranty Rebate is in review pending approval
            </p>
          );
          setStatus(message);
        }
        else if(status.getStatusByTrackingNumber.trackedStatus.includes("APPROVED_STATUS")){
          let message = (
            <>
              <p>
                Your rebate has been approved and will be processed shortly.
                Please allow 3-4 business days to receive your rebate from the date approved
              </p>
              <p>{arrofstatus[1]}</p>
            </>
          );
          setStatus(message);
        }
        else if(status.getStatusByTrackingNumber.trackedStatus.includes("SHIPPED_VIRTUAL")){
          let message = (
            <>
              <p>
                You should receive an email for your Virtual Visa within 1-3 business days.
              </p>
            </>
          );
          setStatus(message);
        }
        else if(status.getStatusByTrackingNumber.trackedStatus.includes("SHIPPED_PHYSICAL")){
          let message = (
            <>
              <p>
                Your payment has been processed. You will receive an email to confirm your mailing address. Once confirmed, you should receive your card within 10-15 business days from the shipping date.
              </p>                         
            </>
          );
          setStatus(message);
        }
        else if(status.getStatusByTrackingNumber.trackedStatus.includes("SHIPPED_CHECK")){
          let message = (
            <>
              <p>
                Your rebate has shipped. You will receive your rebate within 10-15 business days.
              </p>                         
            </>
          );
          setStatus(message);
        }
        else if(status.getStatusByTrackingNumber.trackedStatus.includes("PROCESSING_STATUS")){
          let message = (
            <>
              <p>
                Your Warranty Rebate has been processed and will move to shipped status shortly.
              </p>                         
            </>
          );
          setStatus(message);
        }
      },
      onError(err) {       
              setStatus(err.message)
      },
      fetchPolicy: 'network-only'
  })


    const handleFormSubmit = () => {
        let tracking = form.getFieldValue().trackingNumber
        getStatus({ variables: { trackingNumber: +tracking } })
    }


    return (
      <section>
        <CodeBanner />
        <Navigation />
        <Header />
        <Container className="py-4">
          <Row className="justify-content-center py-4 text-center">
            <Col md={6}>
              <h2 className="text-center display-6">
                Track your rebate status
              </h2>
              <p className="mb-0">
                Enter your Rebate Status Tracking Number to see the status of
                your rebate.
              </p>
              <p>
                Your Rebate Status Tracking Number is provided in the
                confirmation email you received for submitting your rebate.
              </p>
            </Col>
          </Row>
        </Container>
        <Container className='my-5 py-4 border'>

          <Row className="py-4 justify-content-start text-center">
            <Col md={6} className="px-4">
            <h6 className='border-bottom pb-2 mb-3'>Tracking Number</h6>
              
              <Form form={form} name="dynamic_rule" onFinish={handleFormSubmit}>
                <Form.Item
                  name="trackingNumber"
                  rules={[
                    {
                      required: true,
                      message:"Tracking Number is required"
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Value should contain only numbers.",
                    },
                  ]}
                >
                  <Input
                    placeholder={"Rebate Status Tracking Number"}
                    className='w-100'
                  />
                </Form.Item>
                <Form.Item>
                    <button
                        type='primary'
                      className="dahon-btn-primary btn-block w-100 p-2"
                    >
                      Submit
                    </button>
                </Form.Item>

              </Form>
            </Col>
            <Col md={6} className="px-4 pt-4 pt-md-0">
            <h6 className='border-bottom pb-2 mb-3'>Current Status</h6>
              <div>{status}</div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </section>
    );
}

export default TrackPayment;