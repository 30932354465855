import React, {Fragment} from 'react';
import 'antd/dist/antd.css';
import { Container, Col, Row } from 'react-bootstrap';
import './Footer.css';


const Footer = () => {

    return (
        <section className='footer'>
           <Container fluid className='p-4 p-md-5'>
            <Row className='border-bottom'>
                <Col>
                    <p className='small text-center'>
                    Dahon Visa® Prepaid card is issued by Pathward® , N.A., Member FDIC, pursuant to a license from Visa ® U.S.A. Inc. Card can be used everywhere Visa debit cards are accepted.
                    </p>
                </Col>
            </Row>
                <Row className='justify-content-between pt-4'>
                    <Col sm={4}>
                        <ul className='footer-nav'>
                            <li>
                                <a href='https://app.termly.io/document/privacy-policy/a800b269-f5d8-4518-b89e-73d8500a29d2' target={'_blank'} className='footer-link me-2'>Privacy Policy</a>
                            </li>
                            <li>|</li>
                            <li>
                                <a href='https://app.termly.io/document/terms-of-use-for-website/8a62abe5-558e-4bd5-9f18-f400014b43c1' target={'_blank'} className='footer-link ms-2'>Terms of Service</a>
                            </li>
                        </ul>
                    </Col>
                    <Col sm={4}>
                        <p className='small text-center'>
                            ©{new Date().getFullYear()} Dahon Bike. All Rights Reserved.
                        </p>
                    </Col>
                    <Col md={4}>
                        <p className='small text-start text-md-end'><a href='https://bestpayment.solutions/' target={'_blank'} className='footer-link ms-2'>Powered by Best Payment Solutions</a></p>
                    </Col>
                </Row>
           </Container>
        </section>
    );
}

export default Footer;