import React, { Fragment, useState } from 'react';
import { Container } from 'react-bootstrap';
import './CodeBanner.css';


const CodeBanner = () => {

  return (
    <>
      <Container fluid className='codebanner py-2 fs-5 mb-2'>
        <p className='my-0 fw-light'>
          Use the code: <span className='fw-normal'>FREESHIPBPS</span> at checkout for free shipping on accessories at the <a href='https://usa.dahon.com/collections/accessories' target="_blank" className='banner-link'>Dahon webstore</a>.
        </p>
      </Container>
    </>
  );
}

export default CodeBanner;