import React, { Fragment, useState } from "react";
import "antd/dist/antd.css";
import "./DahonWarrantyRebate.css";
import Header from "./Header";
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";
import Navigation from "./Navigation";
import CodeBanner from "./CodeBanner";

const Warranty = () => {
  return (
    <section>
        <CodeBanner />
      <Navigation />
      <Header />
      <Container className="my-5">
        <Row>
          <Col>
            <h2 className="display-6 pb-4">Dahon Limited Warranty</h2>
            <h5>Scheduled Maintenance</h5>
            <p>
              Proper care of your DAHON bicycle requires regular maintenance,
              inspection, and adjustment. New bicycles must be assembled and
              adjusted by an{" "}
              <a
                href="https://usa.dahon.com/pages/store-locator"
                target="_blank"
                className="dahon-link"
              >
                authorized DAHON reseller
              </a>
              .
            </p>
            <p>
              Upon every ride DAHON bicycles should be inspected for properly
              tensioned folding latches, free play, as well as changes in ride
              quality. Any change should be checked and adjusted before riding.
              If you are unsure about a function or use of your DAHON bicycle,
              return to your authorized DAHON reseller for instruction. This
              includes folding, unfolding, adjustment, shifting, and braking.
            </p>
            <h5>3 months after purchase</h5>
            <p>
              Your DAHON bicycle must be returned to your original authorized
              DAHON reseller for inspection and adjustment.
            </p>
            <h5>Yearly Maintenance</h5>
            <p>
              Annual maintenance at the bicycle owner's expense is required. A
              claim may not be considered without proper maintenance records.
            </p>
            <h5>DAHON Limited Warranty (excluding China)</h5>
            <p>
              We warrant our bicycle frames, handle posts and rigid forks to be
              free of defects in materials and workmanship for a period of 5
              years. In addition we warrant all non-wear components for a period
              of 2 years. Certain individual component warranties and local law
              may supersede this warranty policy.
            </p>
            <p>
              This warranty is limited to the repair or replacement of a
              defective frame, handlepost, fork or component, and is the sole
              remedy of the warranty. This warranty applies to the original
              retail purchaser of the bicycle and is non-transferable. This
              warranty only covers DAHON bicycles sold through authorized DAHON
              resellers, and maintained per the Scheduled Maintenance explained
              above. Any replacement item is covered by the original warranty
              term, being from the purchase date of the complete bicycle.
            </p>
            <p>
              Your warranty does not cover normal wear and tear, abuse, stunt
              riding, commercial use, improper assembly or maintenance,
              installation of parts or accessories not originally intended or
              compatible, installation of power systems such as engines or
              electric motors, damage due to accident, weather or neglect, or
              modification of the bicycle.
            </p>
            <p>
              A defect in material or workmanship is defined as; “A flaw,
              failure or cosmetic blemish caused by the manufacturing process or
              material that was not consistent with the original design”. This
              warranty implies no strength or fatigue value beyond regional
              government standards. This warranty does not mean “indestructible”
              as all materials can fail if pushed beyond design and
              manufacturing limits.
            </p>
            <p>
              Wear items are defined as; tires, tubes, brake pads, brake and
              gear cables and housing, chains, gears, grips, and saddles. All of
              these items are subject to varying wear life depending on use,
              load, weather, road conditions etc. Premature wear or “defect”
              will be taken into account in any claim involving a wear item.
            </p>
            <h5>Exclusions</h5>
            <p>
              All damage resulting from Commercial use, accident, misuse, abuse,
              neglect, improper maintenance, or from anything other than normal
              and ordinary use of the bicycle.
            </p>
            <p>
              All damage resulting from off-road or stunt riding, multiple
              passengers, or cargo hauling.
            </p>
            <p>Any paint damage, rust, or modifications made to the bicycle.</p>
            <p>
              DAHON bicycles sold in mainland China fall under a separate policy
              which is non-transferable outside of mainland China.
            </p>
            <h5>Making a Warranty Claim</h5>
            <p>
              It is advised that you should{" "}
              <a
                href="https://dahonrebate.com"
                target="_blank"
                className="dahon-link"
              >
                register your bicycle
              </a>{" "}
              with DAHON in order to assist with future warranty claims.
            </p>
            <p>
              You should bring your DAHON bicycle to your original reseller if
              possible. If this is not possible, please consult the{" "}
              <a
                href="https://usa.dahon.com/pages/store-locator"
                target="_blank"
                className="dahon-link"
              >
                Store Finder
              </a>{" "}
              to find your nearest DAHON authorized reseller.
            </p>
            <p>
              All DAHON claims must be made through an Authorized DAHON
              reseller. All shipping and transport costs are the responsibility
              of the bicycle owner. Labor costs beyond what your original
              reseller offers are the responsibility of the bicycle owner.
            </p>
            <h5>What Items are Required for After Service?</h5>
            <p>1) Photo of the bicycle as a whole</p>
            <p>2) Photos of frame number (on bottom bracket or head tube)</p>
            <p>3) Photos of broken parts from different angles, or videos.</p>
            <p>
              4) Photos of the carton, outside and inside (to check if damage is
              due to shipment or bad packaging)
            </p>
            <p>5) Warranty Card, purchase bill or receipt.</p>
            <p>
              If DAHON agents do not accept your warranty claim because you are
              unable to provide the relevant information mentioned above, please
              send an email to{" "}
              <a href="mailto:montier@dahon.com" className="dahon-link">
                montier@dahon.com
              </a>
            </p>
            <p>
              All claims are subject to assessment by DAHON. Warranty
              replacement will not be given for components not returned (if
              requested) to DAHON.
            </p>
            <p>
              The consumer should be aware that bicycle specifications may be
              subject to change without notice.
            </p>
            <p>
              NOTE: This warranty does not affect the statutory rights of the
              consumer. Where applicable, local laws take precedent over this
              warranty contract.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Warranty;
