import { type } from "@testing-library/user-event/dist/type"
import React from "react"

let cleartime
export const debounce = (lazyQuery) => {
  clearTimeout(cleartime)
  cleartime = setTimeout(() => {
    lazyQuery()
  }, 1000)
}

export const setErrorMessage = (fieldVal, fieldName, formName, errorMessage) => {
  formName.setFields([
    {
      name: fieldName,
      errors: [errorMessage],
    },
  ])

}

export const getBpsAccountUrl = (token, type) => {
  const baseUrl = process.env.REACT_APP_MQ_URL
  return `${baseUrl}/create-account?redemptionToken=${token}&type=${type}`
}