import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Image } from 'antd';
import 'antd/dist/antd.css';

const CardBalanceContent = () => {
    return (
        <>
        <Container className="py-5 vh-fill">
            <Row>
                <Col>
                    <h5 className="display-6">Please check the URL on the back of your card</h5>
                    <p className="mb-0">Follow the URL on the back of your Visa Reward Card to activate or check your card's balance</p>
                </Col>
            </Row>
            <Row className="pt-5 text-center justify-content-center mb-5">
                <Col lg={3}>
                    <Image src="images/Card Balance - BPSmyaccount.png" alt="Card sample" width="200px" className="d-block" />
                    <p><a href="https://bpsmyaccount.com" className="BlueLink fs-24">BPSmyaccount.com</a></p>
                </Col>
                <Col lg={3}>
                    <Image src="images/bpscardaccount.png" alt="Card sample" width="200px" className="d-block" />
                    <p><a href="https://bpscardaccount.com" className="BlueLink fs-24">BPScardaccount.com</a></p>
                </Col>
            </Row>
        </Container>
        </>
    );
}
export default CardBalanceContent;
