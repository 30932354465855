import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { Container, Col, Row, Button, InputGroup } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import "./DahonWarrantyRebate.css";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Input, Select, message as popUpMessage } from "antd";
import { patterns } from "../Constants";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CodeBanner from "./CodeBanner";

const { TextArea } = Input;

const subject = [
  "Dahon Payment Inquiry",
  "My Dahon Payment Status",
  "My Dahon Visa Reward Card",
  "My Dahon Check",
  "My Dahon Virtual Visa Card",
  "Update Information for my Dahon Payment",
  "Dahon Payment Email not Received",
  "Dahon Payment Expired",
  "Dahon Payment Is Incorrect Amount",
  "Tracking Number Request for Dahon Payment",
];

const Contact = () => {
  const [form] = Form.useForm();

  const redirect = useNavigate();
  const onFinish = async (values) => {
    const { subject, First_and_Last_Name, Email_Address, message } = values;
    try {
      const res = await sendContactDetails({
        variables: {
          subject,
          First_and_Last_Name,
          Email_Address,
          message,
        },
      });

      if (res.data.contactUs.message == true) {
        redirect("/message-status");
      }
    } catch (error) {
      popUpMessage.error({
        content: "Failed to save",
        style: {
          marginTop: "10vh",
          fontSize: "1vw",
        },
        duration: 5,
      });
    }
  };

  const SENDCONTACTDETAILS = gql`
    mutation contactUs(
      $message: String!
      $subject: String!
      $First_and_Last_Name: String!
      $Email_Address: String!
    ) {
      contactUs(
        input: {
          name: $First_and_Last_Name
          subject: $subject
          message: $message
          email: $Email_Address
        }
      ) {
        message
      }
    }
  `;
  const [sendContactDetails] = useMutation(SENDCONTACTDETAILS);

  const { t } = useTranslation();

  return (
    <section>
      <CodeBanner />
      <Navigation />
      <Header />
      <Container className="my-5">
        <Row>
          <Col>
            <h2 className="display-6 pb-4">Contact Us</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={7} className="pe-sm-5 order-2 order-sm-1 border-end">
            <h5>E-Customer Service</h5>
            <Form
              name="dynamic_rule"
              requiredMark="optional"
              onFinish={onFinish}
              form={form}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                as={Col}
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: true,
                    message: "Select a subject.",
                  },
                ]}
              >
                <Select>
                  {subject.map((sub) => {
                    return <Select.Option value={sub}>{sub}</Select.Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label={t("First_and_Last_Name")}
                name="First_and_Last_Name"
                rules={[
                  {
                    required: true,
                    message: "Enter your first and last name.",
                  },
                  {
                    pattern: patterns.onlyChar,
                    message: "Special character is not allowed",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("Email_Address")}
                name="Email_Address"
                rules={[
                  {
                    required: true,
                    message: "Enter your e-mail address.",
                  },
                  {
                    type: "email",
                    message: "please enter valid email address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="message"
                label="Your Message"
                rules={[
                  {
                    required: true,
                    message: "Enter a breif explanation about your query",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <p className="small">
                By clicking "SUBMIT," I consent to Dahon North America
                contacting me to provide information on my Dahon Warranty
                Registration rebate. Dahon North America can call or text me
                (including SMS or MMS) (including via prerecorded messages
                and/or automated technology belonging to or hosted by third
                parties) at the telephone number I provided above even if it is
                on a state or Federal Do Not Call List. My consent is not a
                condition of purchase. Please see our Privacy Statement and
                Terms of Use.
              </p>
              <Form.Item>
                <Button type="submit" className="dahon-btn-primary">
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col sm={5} className="ps-sm-7 order-1 order-sm-2">
            <h5>Contact by phone:</h5>
            <p className="mb-3">
              <a href="tel:850-750-7458">850-750-7458</a>
            </p>
            <h5>Hours Of Operation</h5>
            <p>Mon - Fri | 8:00 AM - 4:00 PM PST</p>
            <h5>Cardholder Services Contact Information</h5>
            <p>
              <a href="tel:1-844-725-8818">1-844-725-8818</a> in the U.S and
              Canada<strong>(toll free)</strong>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Contact;
