import React, { Fragment, useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Pagination, Spin, Card, List } from "antd";
import { Container, Col, Row, Image } from "react-bootstrap";
import CodeBanner from "./CodeBanner";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import "./DahonWarrantyRebate.css";
import "./QualifyingBikes.css";

const data = [
    {
        image: "/images/DreamD6.png",
        model: "Dream D6",
        map: "MAP $499",
        rebateAmount: "$25 Rebate"
    },
    {
        image: "/images/SUVD6.png",
        model: "SUV D6",
        map: "MAP $559",
        rebateAmount: "$25 Rebate"
    },
    {
        image: "/images/HitPandemicSpecial.png",
        model: "Hit Pandemic Special",
        map: "MAP $559",
        rebateAmount: "$25 Rebate"
    },
    {
        image: "/images/KidKing.png",
        model: "Kid King",
        map: "MAP $599",
        rebateAmount: "$25 Rebate"
    },
    {
        image: "/images/Piazza.png",
        model: "Piazza D7",
        map: "MAP $639",
        rebateAmount: "$25 Rebate"
    },
    {
        image: "/images/VybeD7.png",
        model: "Vybe D7",
        map: "MAP $649",
        rebateAmount: "$25 Rebate"
    },
    {
        image: "/images/MUD8.png",
        model: "MU D8",
        map: "MAP $799",
        rebateAmount: "$40 Rebate"
    },
    {
        image: "/images/MarinerD8.png",
        model: "Mariner D8",
        map: "MAP $869",
        rebateAmount: "$50 Rebate"
    },
    {
        image: "/images/MUD9.png",
        model: "MU D9",
        map: "MAP $929",
        rebateAmount: "$50 Rebate"
    },
    {
        image: "/images/LaunchD8.png",
        model: "Launch D8",
        map: "MAP $999",
        rebateAmount: "$50 Rebate"
    },
    {
        image: "/images/BrizaD8.png",
        model: "Briza D8",
        map: "MAP $1,049",
        rebateAmount: "$50 Rebate"
    },
    {
        image: "/images/MUD10.png",
        model: "MU D10",
        map: "MAP $1,099",
        rebateAmount: "$50 Rebate"
    },
    {
        image: "/images/MUD11.png",
        model: "MU D11",
        map: "MAP $1,199",
        rebateAmount: "$50 Rebate"
    },
    {
        image: "/images/K3Plus.png",
        model: "K3 Plus",
        map: "MAP $1,529",
        rebateAmount: "$50 Rebate"
    },
    {
        image: "/images/K-OneEBikeHub.png",
        model: "K-One eBike Hub Drive",
        map: "MAP $1,899",
        rebateAmount: "$70 Rebate"
    },
    {
        image: "/images/K-OneEBikeMid.png",
        model: "K-One eBike Mid Drive",
        map: "MAP $2,229",
        rebateAmount: "$70 Rebate"
    },
    {
        image: "/images/UnioE20DiscMidMotor.png",
        model: "Unio E20 Disc Mid Motor",
        map: "MAP $2,599",
        rebateAmount: "$70 Rebate"
    }
];

const QualifyingBikes = () => {

    return (
        <section>
        <CodeBanner />
        <Navigation />
        <Header />
        <Container className="py-4">
                <Row className="justify-content-center pt-4">
                    <Col md={8}>
                        <h2 className="text-center display-6">Qualifying Bikes</h2>
                        <p className="text-center mb-2">
                        Offer valid for any new genuine Dahon bike purchased through a
                        certified dealer or Dahon Direct representative.
                        </p>
                        <p className="text-center small">
                        *Rebate available for US residents only
                        </p>
                    </Col>
                </Row>
                <List
                style={{textAlign:"center"}}
                itemLayout="horizontal"
                size="large"
                pagination={{
                onChange: (page) => {
                    console.log(page);
                },
                pageSize: 6,
                }}
                grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 3,
                }}
                dataSource={data}
                renderItem={(item) => (
                <List.Item
                    key={item.title}
                >
                    <Image fluid src={item.image} className="product-image-fit p-4" />
                    <h5 className="text-uppercase mb-0">{item.model}</h5>
                    <p>{item.map}</p>
                    <span className="bg-dahon-blue px-2 py-1 rounded text-white fs-5">{item.rebateAmount}</span>
                </List.Item>
                )}
            />
        </Container>
        <Footer />
        </section>
    )
}

export default QualifyingBikes;