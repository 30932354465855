import React, { Fragment, useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Container, Col, Row, Accordion,Image, Card, ButtonToolbar } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import "./DahonWarrantyRebate.css";
import CodeBanner from "./CodeBanner";
import { Collapse } from "antd";

const FAQ = () => {

  const [activeKey, setActiveKey] = useState();

  const { Panel } = Collapse;

  useEffect(() => {

    const hash = window.location.hash;

    if ( hash === '#15' ) {

      setActiveKey('15');
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

    }
  }, []);


  return (
    <section>
      <CodeBanner />
      <Navigation />
      <Header />
      <Container className="my-5">
        <Row>
          <Col>
            <h2 className="display-6 pb-4">
                Frequently Asked Questions
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            
            <Collapse accordion defaultActiveKey={['']} activeKey={activeKey}>

              <Panel header="Where do I find my Serial Number on my Dahon Bike?" key="00" onClick={() => {
                if (activeKey === '00') 
                  setActiveKey()
                else 
                  setActiveKey('00')
              }}>

                    Your Dahon bike serial number will be a letter followed by a nine-digit number (example: A123456789) located on the bottom bracket or head tube.
                  <br></br>
                  <Col xs={5}>
                                <Image
                                  fluid
                                  src="images/SerialNumberDiagram.png"
                                />
                </Col>
                
              </Panel>

              <Panel header="When I enter the Serial Number it says “Serial Number Not found”. What should I do?" key="01" onClick={() => {
                if (activeKey === '01') 
                  setActiveKey()
                else 
                  setActiveKey('01')
              }}>

                   Make sure you are referencing the correct serial number (see
                  above). Double-check that you’re reading the number correctly.
                  Thick paint can sometimes make it easy to confuse certain
                  numerals with others. If you’re certain you’ve entered the
                  correct number and you’ve purchased your bike after November 2022,
                please contact the retailer you purchased your bike from.
                
              </Panel>

              <Panel header="When I enter the Serial Number it says “Duplicate Serial Number” What should I do?" key="02" onClick={() => {
                if (activeKey === '02') 
                  setActiveKey()
                else 
                  setActiveKey('02')
              }}>

                  This means Dahon bicycle serial number has already been
                  entered into our system and cannot be registered a second
                  time, and is no longer available for a rebate. If you feel
                  you’ve received this message in error, please contact the
                retailer you purchased your bike from.
                
              </Panel>

              <Panel header="I bought my bike in 2021, is it still eligible for the Warranty Registration Rebate?" key="03" onClick={() => {
                if (activeKey === '03') 
                  setActiveKey()
                else 
                  setActiveKey('03')
              }}>

                   Unfortunately the Dahon Warranty Registration Rebate is only
                  available for Dahon bicycles purchased in November of 2022 and
                  later.
                
              </Panel>

              <Panel header="Is my warranty valid even if I don’t register it?" key="04" onClick={() => {
                if (activeKey === '04') 
                  setActiveKey()
                else 
                  setActiveKey('04')
              }}>

                  Yes, your Dahon manufacturer’s warranty is still valid
                  starting from the date of your purchase even if you do not
                  complete the registration process. However if you do not
                  register your warranty with Dahon you may miss important
                product update information from Dahon.
                
              </Panel>

              <Panel header="What are the terms and conditions of my Dahon Bicycle Warranty?" key="05" onClick={() => {
                if (activeKey === '05') 
                  setActiveKey()
                else 
                  setActiveKey('05')
              }}>

                 <a href='/warranty' className='dahon-link'>Click here</a>
                {" "}to review complete details of your Dahon Bicycle Warranty.
                
              </Panel>

              <Panel header="What if I cannot find my Warranty Rebate tracking number?" key="06" onClick={() => {
                if (activeKey === '06') 
                  setActiveKey()
                else 
                  setActiveKey('06')
              }}>

                 Refer to the confirmation email you received when you
                  submitted your Warranty Rebate from
                  <b> Dahonsupport@bestpayment.solutions</b> or contact customer service
                  from the{" "}
                  <a href="/contactus" className="dahon-link">
                    Contact Us Page
                  </a>
                
              </Panel>

              <Panel header="When will I receive my Warranty Rebate payment?" key="07" onClick={() => {
                if (activeKey === '07') 
                  setActiveKey()
                else 
                  setActiveKey('07')
              }}>

                You should receive your Dahon Warranty Rebate payment within
                3-4 weeks from the date of submission
                
              </Panel>

              <Panel header="What if I want to switch my payment type?" key="08" onClick={() => {
                if (activeKey === '08') 
                  setActiveKey()
                else 
                  setActiveKey('08')
              }}>

                You can switch your Virtual Visa to a Physical Visa anytime by
                  logging into your account on
                    {" "}
                  <a href="https://bpsmyaccount.com/" className="dahon-link">
                    bpsmyaccount.com
                  </a>
                  {" "}
                  and clicking the <b> “Request a Plastic Card” </b> button. You can also
                  call cardholder services directly to request a switch by
                  directly at <b>844-725-8818</b> and
                providing them with your card information.
                
              </Panel>

              <Panel header="What if I need to change my mailing address?" key="09" onClick={() => {
                if (activeKey === '09') 
                  setActiveKey()
                else 
                  setActiveKey('09')
              }}>

                 You will receive a confirmation email after your submission.
                  If you select a Physical Visa Reward card you may update your
                mailing address at this time.
                
              </Panel>

              <Panel header="The link in my email does not work. What should I do?" key="10" onClick={() => {
                if (activeKey === '10') 
                  setActiveKey()
                else 
                  setActiveKey('10')
              }}>

                 Please contact Customer Service with your request by visiting
                  the
                 {" "}
                  <a href="/contactus" className="dahon-link">
                    Contact Us Page
                  </a>
                
              </Panel>

              <Panel header="When does my card expire?" key="11" onClick={() => {
                if (activeKey === '11') 
                  setActiveKey()
                else 
                  setActiveKey('11')
              }}>

                Six months from the date of issuance. Visa Reward Cards expire
                  after midnight EST on the last day of the month of the ‘valid
                thru’ date printed on the front of the card.{" "}
                
              </Panel>

              <Panel header="What if I never received my Visa Reward Card?" key="12" onClick={() => {
                if (activeKey === '12') 
                  setActiveKey()
                else 
                  setActiveKey('12')
              }}>

                Please contact Customer Service with your request by visiting
                  the
                  <a href="/contactus" className="dahon-link">
                    {" "}
                    Contact Us Page{" "}
                  </a>
                
              </Panel>

              <Panel header="When I am tracking my Warranty Rebate payment, what do the different statuses mean?" key="13" onClick={() => {
                if (activeKey === '13') 
                  setActiveKey()
                else 
                  setActiveKey('13')
              }}>

                The statuses and meanings are as follows:<br></br>
                  1. Pending Status -Your Warranty Rebate payment is in review and will be processed shortly.
                    <br></br>
                  2.	Declined Status- Your Warranty Rebate payment has been declined. You can reach out to customer service for assistance and refer to your declined email for the declined reason.
                    <br></br>
                  3.	Approved Status- Your Warranty Rebate payment has been approved and will move to shipped status shortly.
                    <br></br>
                  4.	Shipped Status - Your Warranty Rebate payment has been shipped. You should receive an email for your Virtual Visa within 1-3 business days. If you selected a Physical Visa Reward Card you should receive it in the mail within 7-10 business days from the shipping date.
                <br></br>
                
              </Panel>

              <Panel header="Who should I contact if I am waiting for a payment?" key="14" onClick={() => {
                if (activeKey === '14') 
                  setActiveKey()
                else 
                  setActiveKey('14')
              }}>

                You will receive a confirmation email 3-5 weeks after submission. Once you confirm your mailing address, you should receive your Dahon Warranty Rebate payment within 1-2 weeks. At any time you can track the status of your payment on this website. If you still need assistance please contact Customer Service with your request by visiting the <a href="/contactus" className="dahon-link"> Contact Us Page</a>.

              </Panel>

              <Panel header="What does my receipt need to display for my rebate to be approved?" key="15" onClick={() => {
                if (activeKey === '15') 
                  setActiveKey()
                else 
                  setActiveKey('15')
              }}>

                You will need to upload a scan or image of your sales receipt which clearly shows purchase date, purchaser name or dealer name and purchase amount.
                <p></p>
                <b>NOTE :{" "}</b>
                Some online order transactions conclude with an 'order summary' page which may not contain all required information, especially when viewed on mobile devices, and will result in a declined rebate submission. We advise you to find an 'order detail' page and confirm that all the above required information is visible.

              </Panel>

            </Collapse>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default FAQ;
