import { InboxOutlined,QuestionCircleOutlined  } from "@ant-design/icons";
import AWS from "aws-sdk";
import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { gql, useQuery, useMutation, useLazyQuery, from } from "@apollo/client";
import { AutoComplete, Divider, message, Radio, Spin,DatePicker } from "antd";
import Footer from "./Footer";
import "./DahonWarrantyRebate.css";
import { Form, Input, Select, Space, Checkbox, Upload, Alert } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { cities, patterns, rewardTypes } from "../Constants";
import ErrorList from "antd/lib/form/ErrorList";
import Navigation from "./Navigation";
import Header from "./Header";
import { Tooltip } from 'antd';
import { debounce, setErrorMessage } from "../utils/helper";
import CodeBanner from "./CodeBanner";
const PaymentsForm = () => {
  const [form] = Form.useForm();
  const [referForm] = Form.useForm();
  const { t } = useTranslation();
  const redirect = useNavigate();
  const { Option } = Select;
  const [selected, setSelected] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const [token, setToken] = useState();
  const [virtualVisaDisable, setvirtualVisaDisable] = useState(false);
  const [check, setCheck] = useState(false);
  const [disable, setDisable] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [getParam, setParam] = useSearchParams();
  const [paymentReemed, setpaymentReemed] = useState(false);
  const [disablestatus, setDisableStatusForSubmit] = useState(false);
  const [fileUploadName, setFileUploadName] = useState([]);
  const [alertMessage, setAlertMessage] = useState();
  const [firstAlert, setFirstAlert] = useState({});
  const [otherSuveyInput, setOtherSuveyInput] = useState("");
  const [bikeModel, setBikeModel] = useState();
  const [checked, setChecked] = useState(true);
  const [survey6, setSurvey6] = useState();
  const [dateError, setDateError] = useState();
  const [dateToSend, setDateToSend] = useState();
  const [bikeModelError, setBikeModelError] = useState(false);
  const dateFormat="MM-DD-YYYY";
  
  const [isSerialNumberInvalid, setInvalidSerialNumberFieldError] = useState(false);
  const [isDuplicate, setDuplicateError] = useState(false);
  const [isCombinationInvalid, setInvalidCombination] = useState(false);
  const { Dragger } = Upload;

  const TooltipContent=(
    <>
     Your Dahon bike serial number will be a letter followed by a nine-digit number (example: A123456789) located on the bottom bracket or head tube.<br></br>
      <Image
          fluid
          src="images/SerialNumberDiagram.png"
      />
    </>
  );

  const propsUpload = {
    accept: "image/png, image/jpeg, application/pdf",
    multiple:true,
    onRemove(file) {
      let cleanedFileName = file.name.replace(/[^a-zA-Z0-9\.]/g, "");
      let finalFileName = process.env.REACT_APP_FILE_NAME + "/" + cleanedFileName;
      let filearr=fileUploadName;
      let updatedfileslist=filearr.filter((filename)=>finalFileName !== filename);
      setFileUploadName(updatedfileslist);
    },
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onRemove,
      onProgress,
      onSuccess,
      withCredentials,
    }) {
      const uploadFile = () => {
        setAlertMessage();
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
          region: "us-west-2",
        });

        const S3 = new AWS.S3();
        let cleanedFileName = file.name.replace(/[^a-zA-Z0-9\.]/g, "");
        let finalFileName = process.env.REACT_APP_FILE_NAME + "/" + cleanedFileName;
        const objParams = {
          ACL: "public-read",
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: finalFileName,
          Body: file,
          ContentType: file.type,
        };

        S3.putObject(objParams)
          .on("httpUploadProgress", function ({ loaded, total }) {
            onProgress(
              {
                percent: Math.round((loaded / total) * 100),
              },
              file
            );
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              onSuccess(setFileUploadName((old)=>[...old,finalFileName]));
            }
          });
      };

      file.size >= 5 * 1024 * 1024
        ? setAlertMessage("file size should be below 5mb") &&
          window.scrollTo(0, 100)
        : file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "application/pdf"
        ? uploadFile()
        : setAlertMessage("file type not allowed") && window.scrollTo(0, 100);
    },
  };

  const checkPaymentType = (amount) => {
    amount > 1000 && amount <= 2500
      ? setvirtualVisaDisable(true)
      : amount > 2500
      ? setvirtualVisaDisable(true) && setCheck(true)
      : setvirtualVisaDisable(false) && setCheck(false);
    if (amount <= 500) {
      setCheck(true);
    }
  };

  const AddRebate = gql`
    mutation addRebate(
      $bikeModel: String
      $bikeSerialNumber: String!
      $customerFirstName: String!
      $customerLastName: String!
      $customerAddress1: String!
      $customerAddress2: String
      $customerCity: String!
      $customerState: String!
      $customerZip: String!
      $customerEmail: String!
      $customerPhoneNumber: String!
      $rewardType: RewardType!
      $receiptFilename: [String]!
      $surveyAnswer1: [String]
      $surveyAnswer2: [String]
      $surveyAnswer3: [String]
      $surveyAnswer4: [String]
      $surveyAnswer5: [String]
      $referralEmail: String
      $referralFirstName: String
      $referralLastName: String
      $dateOfSale:Date
    ) {
      addRebate(
        input: {
          bikeModel: $bikeModel
          bikeSerialNumber: $bikeSerialNumber
          customerFirstName: $customerFirstName
          customerLastName: $customerLastName
          customerAddress1: $customerAddress1
          customerAddress2: $customerAddress2
          customerCity: $customerCity
          customerState: $customerState
          customerZip: $customerZip
          customerEmail: $customerEmail
          customerPhoneNumber: $customerPhoneNumber
          rewardType: $rewardType
          receiptFilename: $receiptFilename
          surveyAnswer1: $surveyAnswer1
          surveyAnswer2: $surveyAnswer2
          surveyAnswer3: $surveyAnswer3
          surveyAnswer4: $surveyAnswer4
          surveyAnswer5: $surveyAnswer5
          referralEmail: $referralEmail
          referralFirstName: $referralFirstName
          referralLastName: $referralLastName
          dateOfSale:$dateOfSale
        }
      ) {
        trackingNumber
      }
    }
  `;

  const [rebateadd] = useMutation(AddRebate);

  const SERIAL_NUMBER_CHECK = gql`
    query ($serialNumber: String!, $model: String!) {
      serialNumberCheck(model: $model, serialNumber: $serialNumber)
    }
  `;

  const [checkserialnumber] = useLazyQuery(SERIAL_NUMBER_CHECK, {
    onCompleted(data) {
      setFirstAlert({});
      let validate = data.serialNumberCheck;
      if (validate === false) {
        form.setFields([
          {
            name: "serialnumber",
            errors: ["Invalid serial number"],
          },
        ]);
      }
      else if(validate){
        form.setFields([
          {
            name: ['serialnumber'],
            errors: []
          }
        ]);
        form.setFields([
          {
            name: 'bikemodel',
            errors: []
          }
        ]);
      }
      
    },
    onError(err) {
      setFirstAlert({
        errorMessage: err.message,
        type: "error",
      });
      if(err.message.includes("Invalid serial number")){
        setInvalidSerialNumberFieldError(true);
        setInvalidCombination(false);
        form.setFields([
          {
            name: 'serialnumber',
            errors: ['Invalid Serial Number'],
          },
       ]);
      }
      else if(err.message.includes("Rebate with this serial number already exists!")){
        setDuplicateError(true);
        setInvalidCombination(false);
        form.setFields([
          {
            name: 'serialnumber',
            errors: ['Rebate with this serial number already exists!'],
          },
       ]);
      }
      else if(err.message.includes("Combination of serial number and model is not valid")||err.message.includes("No model associated with this serial number")){
        setInvalidCombination(true);
        form.setFields([
          {
            name: 'serialnumber',
            errors: ['Combination of serial number and model is not valid'],
          },
        ]);
        form.setFields([
          {
            name: 'bikemodel',
            errors: ['Combination of serial number and model is not valid'],
          },
       ]);
        // setInvalidSerialNumberFieldError(true);
      }
      else{
        form.setFields([
          {
            name: 'serialnumber',
            errors: []
          }
        ]);
        setDuplicateError(false)
        setInvalidSerialNumberFieldError(false);
        setInvalidCombination(false);
      }

    },
    fetchPolicy: "network-only",
  });

  const serialCheck = (serialNum) => {
    const { bikemodel } = form.getFieldsValue();
    const errorlist=form.getFieldError('serialnumber');
    setFirstAlert({});
    if(errorlist.length===0){
      checkserialnumber({
        variables: {
          model: bikemodel,
          serialNumber: serialNum,
        },
      });
    }
    else{
      errorlist.map((item)=>{
        if(item==="Rebate with this serial number already exists!"||
        item==="Combination of serial number and model is not valid"||
        item==="Invalid Model Name Or Serial Number Combination"||
        item==="Invalid Serial Number"||
        item==="Serial number already exists!"
        ){
        checkserialnumber({
            variables: {
              model: bikemodel,
              serialNumber: serialNum,
            },
          });
        const bikemodel=form.getFieldsValue(["bikemodel"]);
        getBikeCallback(bikemodel);
        }
      });
    }};

  const BIKE_MODEL_NUMBER = gql`
    query Query($bikeModel: String!) {
      modelSearch(bikeModel: $bikeModel)
    }
  `;

  const CHECK_BIKE_MODEL_NUMBER = gql`
    query Query($bikeModel: String!) {
      bikeModelCheck(bikeModel: $bikeModel)
    }
  `;

  const [getBikeModel] = useLazyQuery(BIKE_MODEL_NUMBER, {
    onCompleted(data) {
      setFirstAlert({});
      let modelSearchArr = data.modelSearch.map((model, index) => ({
        key: index,
        value: model,
      }));
      setBikeModel(modelSearchArr);
    },
    // onError(err) {
    //   setFirstAlert({
    //     errorMessage: err.message,
    //     type: "error",
    //   });
    // },
    fetchPolicy: "network-only",
  });

  const [checkBikeModel] = useLazyQuery(CHECK_BIKE_MODEL_NUMBER, {
    onCompleted(data) {
      setFirstAlert({});
      let validate = data.bikeModelCheck;
      if (validate === false) {
        setBikeModelError(true);
        form.setFields([
          {
            name: "bikemodel",
            errors: ["Invalid model number"],
          },
        ]);
      }
      else{
        form.setFields([
          {
            name: "bikemodel",
            errors: [],
          },
        ]);
        setBikeModelError(false);
      }
    },
    onError(err) {
      setFirstAlert({
        errorMessage: err.message,
        type: "error",
      });
    },
    fetchPolicy: "network-only",
  });

  const getBikeCallback = (val) => {
    // form.setFieldsValue({
    //   serialnumber: "",
    // });
    getBikeModel({
      variables: {
        bikeModel: val,
      },
    });

    checkBikeModel({
      variables: {
        bikeModel: val,
      },
    });
  };

  const paymentFormHandler = async () => {
    if (checked === false) {
      form.setFieldsValue({
        survey1: otherSuveyInput,
      });
    }
    const {
      paymentType,
      bikemodel,
      serialnumber,
      customerFirstName,
      customerLastName,
      customerEmail,
      phone,
      mailingAddress1,
      mailingAddress2,
      city,
      state,
      zip,
      survey5,
      survey4,
      survey3,
      survey2,
      survey1,
      dateOfSale
    } = form.getFieldValue();
    if(!isSerialNumberInvalid){
      try {
        const res = await rebateadd({
          variables: {
            bikeModel: bikemodel,
            bikeSerialNumber: serialnumber,
            customerFirstName: customerFirstName,
            customerLastName: customerLastName,
            customerAddress1: mailingAddress1,
            customerAddress2: mailingAddress2,
            customerCity: city,
            customerState: state,
            customerZip: zip,
            customerEmail: customerEmail,
            customerPhoneNumber: phone,
            rewardType: paymentType,
            receiptFilename: fileUploadName,
            surveyAnswer1: survey1,
            surveyAnswer2: survey2,
            surveyAnswer3: survey3,
            surveyAnswer4: survey4,
            surveyAnswer5: survey5,
            referralLastName: survey6.referralLastName,
            referralFirstName: survey6.referralFirstName,
            referralEmail: survey6.referralEmail,
            dateOfSale:dateToSend,
          },
        });
        if (res) {
          redirect("/payment-status", {
            state: {
              message: "Your rebate form was successfully submitted.",
              trackingNumber: res.data.addRebate.trackingNumber,
            },
          });
        }
      } catch (error) {
        setAlertMessage(error.message);
        window.scrollTo(0, 200);
      }
    }
  };

  const validateReferalForm = () => {
    let validateFields = true;
    let count = 0;
    const { referralEmail, referralFirstName, referralLastName } =
      referForm.getFieldsValue();
    if (
      referralEmail === "" &&
      referralFirstName === "" &&
      referralLastName === ""
    ) {
      setFirstAlert({});
      verifyHandler();
      return;
    }
    if (referForm.isFieldsTouched()) {
      const fields = referForm.getFieldValue();
      if (Object.keys(fields).length < 3) {
        for (const [key, value] of Object.entries(fields)) {
          if (value === "") {
            count++;
          }
        }

        if(count === Object.keys(fields).length ){
          count = 3
        }
      }
      if (Object.keys(fields).length === 3) {
        for (const [key, value] of Object.entries(fields)) {
          if (value !== "") {
            count++;
          }
        }
      }
      if (count < 3) {
        setErrorMessage(
          referralEmail,
          "referralEmail",
          referForm,
          "Referal Email Address is required"
        );

        setErrorMessage(
          referralFirstName,
          "referralFirstName",
          referForm,
          "Referal First name is required"
        );

        setErrorMessage(
          referralLastName,
          "referralLastName",
          referForm,
          "Referal Last name is required"
        );
        window.scrollTo(0, 200);
        setFirstAlert({
          errorMessage: "All referral fields are required",
          type: "error",
        });
      } else {
        setAlertMessage();
        setFirstAlert({});
        verifyHandler();
      }
    } else {
      setAlertMessage();
      setFirstAlert({});
      verifyHandler();
    }
  };

  const verifyHandler = () => {
    if (fileUploadName === undefined) {
      form.setFieldsValue({
        receiptFilename: null,
      });
    }

    const referalFileds = referForm.getFieldsValue();

    setSurvey6({
      referralFirstName: referalFileds.referralFirstName,
      referralLastName: referalFileds.referralLastName,
      referralEmail: referalFileds.referralEmail,
    })
    
    form
    .validateFields()
    .then((check) => {
        setAlertMessage();
        window.scrollTo(0, 300);
        setDisable(true);
        setHidden(false);
      })
      .catch((err) => {
        window.scrollTo(0, 300);
        setAlertMessage(
          "Either the field is required or the value you have entered is wrong"
        );
      });
  };

const onChange=(dateobj,value)=>{
  let datearr=value.split("-");
  let [month,date,year]=datearr;
  let sendDateString=`${year}-${month}-${date}`;

  let validationanswer=dateobj.isBefore('2022-11-01');

  setDateToSend(sendDateString); 
  
  if(validationanswer){
    setDateError(true);
  }
  else{
    setDateError(false);
  }

}
const checkserialOnPaste=(e)=>{
  setInvalidSerialNumberFieldError(false);
  setDuplicateError(false);
  setInvalidCombination(false);
  const { bikemodel } = form.getFieldsValue();
  bikemodel === undefined
    ? setFirstAlert({
        errorMessage: "Select the model first",
        type: "warning",
      })
    : bikemodel !== ""
    ? debounce(() => serialCheck(e))
    : setFirstAlert({
        errorMessage: "Select the model first",
        type: "warning",
      }); 
}

  return (
    <section>
        <CodeBanner />
      <Navigation />
      <Header />
      <Container>
        <Row className="text-center py-4">
          <Col>
            <h2>Register your new Dahon</h2>
            <p className="mb-0">
              Complete your warranty registration and submit your rebate
              request.
            </p>
            <p>
              Upon completion, you will receive an email from us providing you a
              tracking number.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form
              form={form}
              layout="vertical"
              requiredMark="optional"
              name="dynamic_rule"
              onFinish={paymentFormHandler}
            >
              <h5>Bike Information</h5>
              <Row
                className="mb-2"
                hidden={alertMessage !== undefined ? false : true}
              >
                <Col md={8}>
                  <Alert message={alertMessage} type="error" />
                </Col>
              </Row>
              <Row
                className="mb-2"
                hidden={Object.keys(firstAlert).length !== 0 ? false : true}
              >
                <Col md={8}>
                  <Alert
                    message={firstAlert.errorMessage}
                    type={firstAlert.type}
                  />
                </Col>
              </Row>

              <Row className="mb-4 border-bottom pb-2">
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    label={"Bike Model"}
                    name={"bikemodel"}
                    rules={[
                      {
                        required: true,
                        message: "Bike model is required",
                      },
                      // ({ getFieldValue }) => ({
                      //   validator(_, value) {
                      //     if(isCombinationInvalid){
                      //       return Promise.reject("Invalid Model Name & Serial Number Combination");
                      //     }
                      //     else{
                      //       return Promise.resolve();
                      //     }
                      //   }
                      // }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if(bikeModelError){
                            return Promise.reject("Invalid Bike Model");
                          }
                          else{
                            return Promise.resolve();
                          }
                        }
                      }),

                    ]}
                  >
                    <AutoComplete
                      notFoundContent={
                        <Spin
                          className="d-flex justify-content-center"
                          size="small"
                        />
                      }
                      onKeyDown={(e) => {
                        debounce(() => getBikeCallback(e.target.value));
                      }}
                      onSelect={
                        (e)=>{
                          debounce(() => getBikeCallback(e));
                        }
                      }
                      disabled={disable}
                      filterOption={bikeModel}
                      options={bikeModel}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    style={{marginBottom:"0px"}}
                    as={Col}
                    label={<>  
                    <span>
                    Bike Serial Number
                      </span> 
                      <Tooltip  placement="bottom" className="p-1" title={TooltipContent}>
                        <QuestionCircleOutlined className="ms-1"/>
                      </Tooltip>
                    </>
                  }
                    name={"serialnumber"}
                    rules={[
                      {
                        required: true,
                        message: "Serial number is required",
                      },
                      {
                        pattern: patterns.onlyChar,
                        message: "Special characters are  not allowed",
                      },
                      {
                        pattern: patterns.DahonserialNumberCheck,
                        message: "Serial number should be a letter followed by a nine digit number",
                      },
                      //  ({ getFieldValue }) => ({
                      //   validator(_, value) {
                      //     if(isSerialNumberInvalid){
                      //       return Promise.reject("Invalid Serial Number");
                      //     }
                      //     else{
                      //       return Promise.resolve();
                      //     }
                      //   }
                      // }),
                      //  ({ getFieldValue }) => ({
                      //   validator(_, value) {
                      //     if(isDuplicate){
                      //       return Promise.reject("Serial number already exists!");
                      //     }
                      //     else{
                      //       return Promise.resolve();
                      //     }
                      //   }
                      // }),
                      //  ({ getFieldValue }) => ({
                      //   validator(_, value) {
                      //     if(isCombinationInvalid){
                      //       return Promise.reject("Invalid Model Name Or Serial Number Combination");
                      //     }
                      //     else{
                      //       return Promise.resolve();
                      //     }
                      //   }
                      // }),

                    ]}
                  >
                    <Input
                      // onPaste={(e)=>checkserialOnPaste(e.target.value)}      
                      // onKeyUp={(e) => {
                      //   setInvalidSerialNumberFieldError(false);
                      //   setDuplicateError(false);
                      //   setInvalidCombination(false);
                      //   const { bikemodel } = form.getFieldsValue();
                      //   bikemodel === undefined
                      //     ? setFirstAlert({
                      //         errorMessage: "Select the model first",
                      //         type: "warning",
                      //       })
                      //     : bikemodel !== ""
                      //     ? debounce(() => serialCheck(e.target.value))
                      //     : setFirstAlert({
                      //         errorMessage: "Select the model first",
                      //         type: "warning",
                      //       }); 
                      // }}
                      disabled={disable}
                    />
                  </Form.Item>
                  <Col md={12}>
                  <span className="small text-secondary"> A letter followed by a nine digit number found on the head
                    tube or bottom bracket of your Dahon bike.
                  </span>
                  </Col>
                </Col>
                <Col md={6}>
                  <Form.Item
                    width="100%"
                    as={Col}
                    label={"Date of Sale"}
                    name={"dateOfSale"}
                    rules={[
                      {
                        required: true,
                        message: "Date is a requried field",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if(dateError){
                           return Promise.reject("The Dahon Warranty Registration Rebate is only available for Dahon bicycles purchased in November of 2022 and later");
                          }
                          else{
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                   
                     <DatePicker  format={dateFormat} style={{width:'100%',}} onChange={onChange}/>
                  </Form.Item>
                </Col>
              </Row>
                
              <Row className="border-bottom pb-5">
                <Col>
                  <h5>Upload Receipt</h5>
                  <b className="mb-3">Date of sale must be showing on the copy of receipt attached</b>
                  <br/>
                  <b className="mb-3" style={{ color: 'red' }}>IMPORTANT: </b>
                  <b className="mb-3">To avoid a declined submission please confirm that:</b>
                  <br/>
                  <b className="mb-5">-Date of sales MUST be showing on the copy of receipt attached</b>
                  <br/>
                  <b className="mb-3">-Purchaser or Dealer Name</b>
                  <br/>
                  <b className="mb-3">-Dahon bike model and Serial Number</b>
                  <br />
                  <b className="mb-3">-<a href="/faq#15">Help with my receipt</a></b>
                  <p></p>
                  <Form.Item
                    name={"receiptFilename"}
                    rules={[
                      {
                        required: true,
                        message: "Receipt is required",
                      },
                    ]}
                  >
                    <Dragger {...propsUpload} disabled={disable}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p>
                        Click or drag file to this area to bike purchase receipt
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <h5>Customer Information</h5>
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        as={Col}
                        className="mb-3"
                        label={t("first_name")}
                        name={"customerFirstName"}
                        rules={[
                          {
                            required: true,
                            message: "First name is required",
                          },
                          {
                            pattern: patterns.onlyChar,
                            message: "Special characters are  not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable} autoComplete="given-name" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        as={Col}
                        className="mb-3"
                        label={t("last_name")}
                        name={"customerLastName"}
                        rules={[
                          {
                            required: true,
                            message: "Last name is required",
                          },
                          {
                            pattern: patterns.onlyChar,
                            message: "Special characters are  not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable} autoComplete="family-name" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("address1")}
                    name={"mailingAddress1"}
                    rules={[
                      {
                        required: true,
                        message: "Address is required",
                      },
                    ]}
                  >
                    <Input autoComplete="address-line1" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("address2")}
                    name={"mailingAddress2"}
                    requiredMark={hidden}
                  >
                    <Input disabled={disable} autoComplete="address-line2" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("city")}
                    name={"city"}
                    rules={[
                      {
                        required: true,
                        message: "City is required",
                      },
                      {
                        pattern: patterns.onlyChar,
                        message: "Special characters are  not allowed",
                      },
                    ]}
                  >
                    <Input disabled={disable} autoComplete="address-level2" />
                  </Form.Item>
                </Col>
                <Col md={3} xs={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("state")}
                    name={"state"}
                    rules={[
                      {
                        required: true,
                        message: "State is required",
                      },
                    ]}
                  >
                    <Select
                      disabled={disable}
                      showSearch={true}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {cities.map((city, index) => {
                        return (
                          <Option key={index} value={city.value}>
                            {city.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={3} xs={6}>
                  <Form.Item
                    name="zip"
                    label={t("zip")}
                    rules={[
                      {
                        required: true,
                        pattern: patterns.zipCheck,
                        message: "Value should only contain numbers.",
                      },
                      {
                        min: 5,
                        message: "Zip should be of 5 digit long.",
                      },
                      {
                        max: 5,
                        message: "Zip should be of 5 digit long.",
                      },
                    ]}
                  >
                    <Input disabled={disable} autoComplete="postal-code" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-4 pb-2 border-bottom">
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    label={t("email")}
                    name={"customerEmail"}
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                      {
                        type: "email",
                        message: "please enter valid email address",
                      },
                    ]}
                  >
                    <Input disabled={disable} autoComplete="email" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    label={t("phone")}
                    name={"phone"}
                    rules={[
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                      {
                        pattern: patterns.phoneNumberCheckPattern,
                        message: "Value should contain just number.",
                      },
                      {
                        min: 10,
                        message: "Phone number should be of 10 digit long.",
                      },
                      {
                        max: 10,
                        message: "Phone number should be of 10 digit long.",
                      },
                    ]}
                  >
                    <Input disabled={disable} autoComplete="tel" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="required-field">
                  <h5>Select Your Payment Type:</h5>
                </Col>
              </Row>
              <Row className="mb-4 pb-2 border-bottom">
                <Col>
                  <Form.Item
                    name={"paymentType"}
                    rules={[
                      {
                        required: true,
                        message: "Payment type is required",
                      },
                    ]}
                  >
                    <Radio.Group disabled={disable}>
                      <Row className="justify-content-between">
                        <Col md className="my-2">
                          <Radio
                            value={rewardTypes.virtual}
                            className="border p-4 w-100 h-100 "
                          >
                            <Row className="justify-content-between">
                              <Col xs={7}>
                                <h6>Dahon Virtual Visa Rewards Card**</h6>
                                <p>
                                  Receive e-code by email. For online purchases
                                  only
                                </p>
                              </Col>
                              <Col xs={5}>
                                <Image
                                  fluid
                                  src="images/DahonVirtualVisa.png"
                                />
                              </Col>
                            </Row>
                          </Radio>
                        </Col>
                        <Col md className="my-2">
                          <Radio
                            value={rewardTypes.physical}
                            className="border p-4 w-100 h-100"
                          >
                            <Row className="justify-content-between">
                              <Col xs={7}>
                                <h6>Dahon Visa Rewards Card**</h6>
                                <p>Receive a physical card by mail</p>
                              </Col>
                              <Col xs={5}>
                                <Image
                                  fluid
                                  src="images/DahonPhysicalVisa.png"
                                />
                              </Col>
                            </Row>
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>
                    Survey:{" "}
                    <span className="small fs-6 fw-normal">(Optional)</span>
                  </h5>
                  <p>
                    Help us make our bikes even better by answering a few
                    questions.
                  </p>
                </Col>
              </Row>
              <Row className="mb-4 pb-2 border-bottom">
                <Col>
                  <h6>1. How did you hear about Dahon?</h6>
                  <Form.Item name={"survey1"}>
                    <Radio.Group disabled={disable}>
                      <Space direction="vertical">
                        <Radio
                          value={"Print advertisement"}
                          onChange={(e) => {
                            setOtherSuveyInput();
                            setChecked(true);
                          }}
                        >
                          Print advertisement
                        </Radio>
                        <Radio
                          value={"Digital advertisement"}
                          onChange={(e) => {
                            setOtherSuveyInput();
                            setChecked(true);
                          }}
                        >
                          Digital advertisement
                        </Radio>
                        <Radio
                          value={"Friend or family"}
                          onChange={(e) => {
                            setOtherSuveyInput();
                            setChecked(true);
                          }}
                        >
                          Friend or family
                        </Radio>
                        <Radio
                          value={"Online Search"}
                          onChange={(e) => {
                            setOtherSuveyInput();
                            setChecked(true);
                          }}
                        >
                          Online Search
                        </Radio>
                        <Radio
                          value={"other"}
                          onChange={(e) => {
                            setChecked(false);
                          }}
                        >
                          Other
                          <Input
                            style={{
                              width: 200,
                              marginLeft: 10,
                            }}
                            value={otherSuveyInput}
                            disabled={checked || disable}
                            onChange={(e) => {
                              setOtherSuveyInput(e.target.value);
                            }}
                          />
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <h6>2. Have you purchased a folding bike before?</h6>
                  <Form.Item name={"survey2"}>
                    <Radio.Group disabled={disable}>
                      <Space direction="vertical">
                        <Radio value={"Yes"}>Yes</Radio>
                        <Radio value={"No"}>No</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <h6>
                    3. Do you own additional bikes? (check all that apply)
                  </h6>
                  <Form.Item name={"survey3"}>
                    <Checkbox.Group disabled={disable}>
                      <Space direction="vertical">
                        <Checkbox value={"Yes, a mountain bike"}>
                          Yes, a mountain bike
                        </Checkbox>
                        <Checkbox value={"Yes, a roadbike"}>
                          Yes, a roadbike
                        </Checkbox>
                        <Checkbox value={"Yes, a gravel or cyclocross bike"}>
                          Yes, a gravel or cyclocross bike
                        </Checkbox>
                        <Checkbox value={"no"}>No</Checkbox>
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                  <h6>
                    4. How will you use your Dahon? (check all that apply)
                  </h6>
                  <Form.Item name={"survey4"}>
                    <Checkbox.Group disabled={disable}>
                      <Space direction="vertical">
                        <Checkbox value={"Commuting to work or school"}>
                          Commuting to work or school
                        </Checkbox>
                        <Checkbox value={"Short trips and errands"}>
                          Short trips and errands
                        </Checkbox>
                        <Checkbox value={"Recreation/exercise"}>
                          Recreation/exercise
                        </Checkbox>
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                  <h6>5. How often do you ride your bike?</h6>
                  <Form.Item name={"survey5"}>
                    <Radio.Group disabled={disable}>
                      <Space direction="vertical">
                        <Radio value={"Once or twice a month"}>
                          Once or twice a month
                        </Radio>
                        <Radio value={"Once or twice a week"}>
                          Once or twice a week
                        </Radio>
                        <Radio value={"Three to five days a week"}>
                          Three to five days a week
                        </Radio>
                        <Radio value={"Almost every day"}>
                          Almost every day
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Divider />
                  <h6> Refer a Friend:{" "}
                    <span className="small fs-6 fw-normal">(Optional)</span>
                    </h6>
                  <div>
                    <Form
                      form={referForm}
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 6 }}
                      layout="horizontal"
                      name="dynamic_rule"
                      onFinish={validateReferalForm}
                    >
                      <Form.Item
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 6 }}
                        label={"Referral First Name"}
                        name={"referralFirstName"}
                        rules={[
                          {
                            pattern: patterns.onlyChar,
                            message: "Special characters are  are not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable}/>
                      </Form.Item>

                      <Form.Item
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 6 }}
                        label={"Referral Last Name"}
                        name={"referralLastName"}
                        rules={[
                          {
                            pattern: patterns.onlyChar,
                            message: "Special characters are  not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable}/>
                      </Form.Item>

                      <Form.Item
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 6 }}
                        label={"Referral Email Address"}
                        name={"referralEmail"}
                        rules={[
                          {
                            type: "email",
                            message: "Enter valid email address",
                          },
                        ]}
                      >
                        <Input disabled={disable}/>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row className="py-4 border-bottom">
                <Col>
                  <p>
                    Note: This rebate cannot be combined with other Dahon North
                    America offers.
                  </p>
                  <p>
                    Eligibility Requirements: Offer available to new purchasers
                    of Dahon bicycles who submit this Rebate Form according to
                    Rebate Terms below.
                  </p>
                  <p>
                    * Rebate Terms: Only available to customers who purchase a
                    new Dahon bicycle from an authorized dealer or agent. Rebate
                    form must be completed and submitted to Dahon within 90 days
                    of the purchase date, along with required documentation.
                    Allow 3-4 weeks for processing. Late submissions and those
                    submitted without proper documentation and signatures will
                    be subject to delay or cancellation. Void where prohibited.
                  </p>
                  <p>
                    ** Reward Card: Dahon Card / Virtual card is issued by
                    Pathward, N.A., Member FDIC, pursuant to a license from Visa
                    U.S.A. Inc. No cash access or recurring payments. Card can
                    be used everywhere Visa debit cards are accepted. Virtual
                    card can be used everywhere Visa debit cards are accepted
                    online, or phone/mail orders. Card/Virtual card valid for up
                    to 6/24 months; unused funds will forfeit after the valid
                    thru date. Terms and conditions apply
                  </p>
                </Col>
              </Row>
              <Row hidden={!hidden} className="py-5 justify-content-center">
                <Col md={3}>
                  <Button
                    onClick={() => {
                      referForm.submit();
                    }}
                    // disabled={isSerialNumberInvalid}
                    className="dahon-btn-primary w-100"
                  >
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
              <Row className="py-5 justify-content-center" hidden={hidden}>
                <Col md={3}>
                  <Button
                    className="dahon-btn-secondary w-100"
                    onClick={() => {
                      setAlertMessage();
                      setHidden(true);
                      setDisable(false);
                    }}
                  >
                    {t("back")}
                  </Button>
                </Col>
                <Col md={3}>
                  <Button type="submit" className="dahon-btn-primary w-100">
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default PaymentsForm;
