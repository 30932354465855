import React, { useState, useEffect } from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { gql, useQuery, useMutation } from '@apollo/client'
import { message, Radio } from 'antd';
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import CodeBanner from "./CodeBanner";
import './Content.css';
import { Form, Input, Select } from 'antd';

import { Link, useLocation } from 'react-router-dom';

const PhysicalCardRedeemed = (state) => {


    return (
        <>
            <CodeBanner />
            <Navigation />
            <Header />
            <Container>

                <Row className="py-5 bps-bg-light justify-content-center text-center">
                    <Col md={6} className="pt-5">
                        <h2 className="bps-midnight fs-48">Visa Reward Card Confirmation</h2>
                        <p className="fs-20">
                        You should receive your card in 10-15 business days.
                        </p>
                    </Col>
                </Row>
                
            </Container>
            <Footer />
        </>
    );
}

export default PhysicalCardRedeemed;